import { combineReducers } from "redux"
import auth from "./AuthReducer"
import toasts from "./ToastsReducer"
import snackbars from "./SnackbarsReducer"
import pipItems from "./PIPReducer"
import campaigns from "./Campaign/CampaignManagementReducer";
import campaignGroup from "./Campaign/CampaignGroupReducer";
import campaignDevice from "./Campaign/CampaignDeviceReducer";
import campaignSummary from "./Campaign/CampaignSummaryReducer";
import userReducer from "./Admin/UserManagementReducer";
import groupReducer from "./Admin/GroupManagementReducers";
import roleReducer from "./Admin/RoleManagementReducers";
import softwares from './Softwares/SoftwareManagementReducer';
import packageType from './Softwares/SoftwarePackageTypeReducer';
import deviceFiltersReducer from './Devices/DeviceFiltersReducer';
import devicesReducer from './Devices/DevicesReducer';
import deviceManagementReducer from './Devices/DeviceManagementReducer';
import reportManagementReducer from './Devices/ReportManagementReducer';
import CampaignHistory from './DeviceOverview/CampaignHistoryReducer';
import DeviceRolldown from './DeviceOverview/DeviceRolldownReducer';
import DeviceShadow from './DeviceOverview/DeviceShadowReducer';
import deviceRegistrationReducer from './Devices/DeviceRegistrationReducer';
import workFlowReducer from './WorkFlow/WorkFlowReducer';
import fileUploadReducer from "./FileUploadReducer";
import CampaignDeviceShadowHistory from './DeviceOverview/CampaignDeviceShadowHistoryReducer';

export const appReducer = combineReducers({
  auth,
  toasts,
  snackbars,
  pipItems,
  campaigns,
  campaignGroup,
  campaignDevice,
  campaignSummary,
  userReducer,
  groupReducer,
  roleReducer,
  softwares,
  packageType,
  deviceFiltersReducer,
  devicesReducer,
  deviceManagementReducer,
  reportManagementReducer,
  CampaignHistory,
  DeviceRolldown,
  DeviceShadow,
  deviceRegistrationReducer,
  workFlowReducer,
  fileUploadReducer,
  CampaignDeviceShadowHistory
})

export const rootReducer = (state, action) => {
  if (action!=undefined && (action.type === 'UN_AUTH' || action.type === 'PRE_AUTH'||action.type === 'SWITCH')) {
    state = undefined
  }

  return appReducer(state, action)
}
