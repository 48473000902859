import axios from "../library/axiosInstance";
import { jwtRefreshtoken } from "../library/utility";
import T from "i18n-react";

export function authenticateUser(uname, pwd) {
  return (dispatch) => {
    dispatch({type: "PRE_AUTH"});
    dispatch({type: "AUTH"});
    const request = {
      "username":uname, 
      "password":pwd,
      "grant_type":"password" 
    }
    const params = Object.keys(request).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(request[key]);
    }).join('&');
    
    axios.post(`/oauth/token`, params, {
      headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": "Basic QUtJQVFSUUxFSlo1TldIRkNJWlg6QUtJQVFSUUxFSlo1TldIRkNJWlg=",
      }
    }).then(response => {
      if(response.status === 200){
        const token = response.data.access_token;
        const refresh_token = response.data.refresh_token;
        const bearer_token = "Bearer "+token;
        axios.defaults.headers.common['Authorization'] = bearer_token;
        axios.post(`/itrams/login`, {"user_name": uname, "password": pwd}).then(res => {
          localStorage.setItem('iTramsToken', token);
          localStorage.setItem('iTramsRefreshToken', refresh_token);
          localStorage.setItem('expiresIn', response.data.expires_in);
          jwtRefreshtoken();
          localStorage.setItem('userData', JSON.stringify(res.data));
          switch (res.data.organization_id) {
            case '2':
              localStorage.setItem("caseBrand", "CNHI");
              break;
            case '3':
              localStorage.setItem("caseBrand", "CUMMINS");
              break;
            case '4':
              localStorage.setItem("caseBrand", "TATA");
              break;
            case '10':
              localStorage.setItem("caseBrand", "MAHINDRA");
              break;
            case '6':
              localStorage.setItem("caseBrand", "TATA");
              break;
            default:
              localStorage.setItem("caseBrand", "");
              break;
          }
          T.setTexts(require(`../../i18n/en.json`));
          dispatch({type: "AUTH_SUCCESS", payload: res.data});
        }).catch((err) => {   
          if(err.response.data.error_code === "401") {
            dispatch({ type: "AUTH_REJECTED_DISP_MES", payload: err.response.data.error_message });
            dispatch({type: "AUTH_REJECTED", payload: err.response.data.error_code});
          } 
          dispatch({ type: "AUTH_REJECTED_DISP_MES", payload: err.response.data.error_message });
          dispatch({type: "AUTH_REJECTED", payload: err.response.data.error_code});
        })
      } else {
        dispatch({ type: "AUTH_REJECTED_DISP_MES", payload: response.message.substring(23) });
        dispatch({type: "AUTH_REJECTED", payload: response.data.error_code});
      }
    }).catch((err) => {
      err.response.status == 401 ? 
        dispatch({ type: "AUTH_REJECTED_DISP_MES", payload: err.response.data.message.substring(23) })
      : err.response.status === 400 ? 
        dispatch({ type: "AUTH_REJECTED_DISP_MES", payload: "There was an error with your Username/Password combination. Please try again." })
      : null;
      dispatch({type: "AUTH_REJECTED", payload: err})
    })
  }
}

export function signOutUser() {
  const auth_token = localStorage.getItem("iTramsToken");
  const refresh_token = localStorage.getItem("iTramsRefreshToken");
  return (dispatch) => {
    axios.delete(`/oauth/token`, { 
      headers: { 
        "Authorization": "Basic QUtJQVFSUUxFSlo1TldIRkNJWlg6QUtJQVFSUUxFSlo1TldIRkNJWlg=", 
        "AUTH-TOKEN": "Bearer "+auth_token, 
        "REFRESH-TOKEN": "Bearer "+refresh_token 
      }
    }).then((response) => {
      if(response.status === 200) {
        localStorage.clear();
        dispatch({type: "UN_AUTH"});
      }
    }).catch(() => {
      localStorage.clear();
      dispatch({type: "UN_AUTH"});
    })
  }
}

export function updateParent(parentModule, parentPath) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_PARENT", payload: { data: { "module": parentModule, "path": parentPath } } });
  }
}