import { getPageCount } from '../../library/utility';
export default function reducer(state = {
  devices: [],
  fetching: false, 
  fetched: false, 
  error: null,
  devicesCount: 0,
  pageCount: 0,
  deviceNextToken:"",
  deviceStatusMessage:""
}, action) {
  switch (action.type) {
    case "FETCH_CAMPAIGN_DEVICE": {
      return {...state, fetching: true, fetched: false }
    }
    case "FETCH_CAMPAIGN_DEVICE_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_CAMPAIGN_DEVICE_FULFILLED": {
      const devicesCt = action.payload.response.data === null ? 0 : action.payload.response.data.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(devicesCt, perPage);
      const devices = action.payload.response.data;
      return {
        ...state, 
        fetching: false, 
        fetched: true, 
        devicesCount:devicesCt, 
        devices: devices,  
        pageCount:numberOfPages, 
        deviceNextToken:action.payload.response.data.next_token
      }
    }
    case "FETCH_DEVICE_COUNT_REJECTED": {
      return {...state, error: action.payload}
    }
    case "CLEAR_CAMPAIGN_DEVICE_DATA": {
      return { ...state, fetching: false, fetched: false, error: null, devicesCount:0, devices: [], pageCount:0, deviceNextToken:"" }
    }
  }
  return state
}
