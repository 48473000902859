import { getPageCount, fetchsortedArray } from '../../library/utility';

export default function reducer(state = {
  deviceHistory: [],
  fetching: false, 
  fetched: false, 
  error: null,
  pageCount: 0,
  deviceHistoryCount: 0,
  nextToken : null,
}, action) {
  switch (action.type) {
    case "FETCH_CAMPAIGN_HISTORY": {
      return {...state, fetching: true}
    }
    case "FETCH_CAMPAIGN_HISTORY_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_CAMPAIGN_HISTORY_FULFILLED": {
      const request = action.payload.request;
      const response = action.payload.response.data;
      const campHistoryCount = state.deviceHistoryCount + response.device_history.length;
      const perPage = request.perPage;
      const numberOfPages = getPageCount(campHistoryCount, perPage);
      let historyList = (request.sortColumnName !== undefined && request.sortOrder !== undefined) ? 
        fetchsortedArray(response.device_history, request.sortColumnName, request.sortOrder) : response.device_history;
      return {...state, 
        fetching: false, 
        fetched: true, 
        error: null, 
        pageCount:numberOfPages,
        deviceHistory: historyList,
        nextToken : response.next_token
      }
    }
    case "CLEAR_CAMPAIGN_HISTORY_DATA": {
      return { ...state, 
        deviceHistory: [], 
        fetching: false, 
        fetched: false, 
        error: null, 
        pageCount: 0, 
        deviceHistoryCount: 0, 
        nextToken : null
      }
    }
  }
  return state
}
