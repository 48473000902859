import { getPageCount, fetchsortedArray } from '../../library/utility';

export default function reducer(state = {
  deviceHistory: [],
  deviceMqttHistory: [],
  fetching: false, 
  fetched: false, 
  error: null,
  pageCount: 0,
  deviceHistoryCount: 0,
  deviceMqttHistoryCount: 0,
  nextToken : null,
  backDrop: false,
  backDropMessage: ""
}, action) {
  switch (action.type) {
    case "FETCH_DEVICE_SHADOW_CAMPAIGN_HISTORY": {
      return {...state, fetching: true}
    }
    case "FETCH_DEVICE_SHADOW_CAMPAIGN_HISTORY_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_DEVICE_SHADOW_CAMPAIGN_HISTORY_FULFILLED": {
      const request = action.payload.request;
      const response = action.payload.response.data;
      const campHistoryCount = state.deviceHistoryCount + response.length;
      const perPage = request.perPage;
      const numberOfPages = getPageCount(campHistoryCount, perPage);
      let historyList = (request.sortColumnName !== undefined && request.sortOrder !== undefined) ? 
        fetchsortedArray(response, request.sortColumnName, request.sortOrder) : response;
      historyList.sort((a, b) => {
        if (a.status_of_container === 'RUNNING' && b.status_of_container !== 'RUNNING') {
          return -1;
        } else if (a.status_of_container !== 'RUNNING' && b.status_of_container === 'RUNNING') {
          return 1;
        } else {
          return 0;
        }
      });
      return {...state, 
        fetching: false, 
        fetched: true, 
        error: null, 
        pageCount:numberOfPages, 
        deviceMqttHistory: historyList,
        nextToken : response.next_token
      }
    }
    case "FETCH_DEVICE_MQTT_HISTORY_FULFILLED": {
      const request = action.payload.request;
      const response = action.payload.response.data;
      const campHistoryCount = state.deviceHistoryCount + response.length;
      const perPage = request.perPage;
      const numberOfPages = getPageCount(campHistoryCount, perPage);
      let historyList = (request.sortColumnName !== undefined && request.sortOrder !== undefined) ? 
        fetchsortedArray(response, request.sortColumnName, request.sortOrder) : response;
      return {...state, 
        fetching: false, 
        fetched: true, 
        error: null, 
        pageCount:numberOfPages, 
        deviceHistory: historyList,
        nextToken : response.next_token
      }
    }
    case "FETCH_DEVICE_MQTT_HISTORY_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "CLEAR_CAMPAIGN_HISTORY_DATA": {
      return { ...state, 
        deviceHistory: [], 
        fetching: false, 
        fetched: false, 
        error: null, 
        pageCount: 0, 
        deviceHistoryCount: 0, 
        nextToken : null
      }
    }
  }
  return state
}